import { KeyboardEvent } from "react";
import _ from "lodash";
import { UPLOAD_FILE_MAXLENGTH } from ".";

const cleanFileName = async (name: string) => {
  // Split the string into two parts: the part before the last dot (.) and the extension
  const parts = name.split(".");
  const beforeExtension = parts.slice(0, -1).join(".");
  const extension = parts.pop();

  // Remove special characters from the part before the extension
  let cleanedString = beforeExtension.replace("&", "");
  cleanedString = beforeExtension.replace(/[^\w\s-]/g, "");

  cleanedString = cleanedString.replace(/[\s]/g, "-");

  // Convert the cleaned part to lowercase and replace spaces with hyphens
  const lowercaseString = cleanedString.toLowerCase();

  // Join the cleaned part with the preserved extension and return the result
  return `${lowercaseString}.${extension}`;
};

function cleanFileNameSync(name: string) {
  // Split the string into two parts: the part before the last dot (.) and the extension
  const parts = name.split(".");
  const beforeExtension = parts.slice(0, -1).join(".");
  const extension = parts.pop();

  // Remove special characters from the part before the extension
  let cleanedString = beforeExtension.replace("&", "");
  cleanedString = beforeExtension.replace(/[^\w\s-]/g, "");

  cleanedString = cleanedString.replace(/[\s]/g, "-");

  // Convert the cleaned part to lowercase and replace spaces with hyphens
  const lowercaseString = cleanedString.toLowerCase();

  // Join the cleaned part with the preserved extension and return the result
  return `${lowercaseString}.${extension}`;
}

const findFile = async (items: any[], file: any) => {
  const found = items.find(
    (item) => {
      let name: string = cleanFileNameSync(item.file?.name);
      return item.category === file.category && name === file.display_name;
    });
  return found;
};

const findFile2 = async (items: any[], file: any) => {
  try {
    return await items.reduce(async (e: any, t: any) => {
      let name: string = await cleanFileName(t.file?.name || t.display_name || t.file_name);
      if (t.category === file.category && name === file.display_name) {
        e = file;
      }
      return e;
    }, {});
  } catch (err) {
    console.log(err);
  }
};

const getUpdatedFiles = async (oldFiles: any[], newFiles: any[], attached: boolean) => {
  return Promise.all(attached ? (oldFiles || []).map(async (file: any) => await findFile2(newFiles, file)) : newFiles?.reduce((e: any[], t: any) => {
    const newItem = oldFiles?.find((attachment: any) => (attachment.category === t.category && attachment.display_name === (t?.file?.name || t?.display_name)));
    if (newItem) {
      e = [...e, newItem];
    }
    return e;
  }, [])).then((res) => {
    return res.filter((item: any) => Object.keys(item)?.length);
  });
};

const getLabel = (arr: any[], value: any) => {
  return arr.find(
    (item) => item.value === value,
  );
};

const limitDigits = (e: any, maxDigits: any) => {
  if (e.target.value.length > maxDigits) {
    e.target.value = e.target.value.slice(0, maxDigits);
  }
};

// method to handle selected files format.
const handleFilesFormat = (files: FileList, category: string) => {
  return Object.keys(files).map(
    (key: any) => {
      const fileSize = files?.[key]?.size ?? 0;
      return {
        category,
        file: files?.[key],
        error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
        isUpload: false,
      };
    });
};

const handleScientificNotation = (event: KeyboardEvent<HTMLInputElement>) => {
  // Check if the pressed key is 'e' or 'E'
  if (event.key.toLowerCase() === "e") {
    // Prevent the default behavior for 'e' and 'E'
    event.preventDefault();
  }
};

const uniqueItemsByKey = (items: any[], key: string) => {
  return _.uniqBy(items, (item: any) => item?.[key]);
};

const sortItemsByKey = (items: any[], key: string) => {
  return items.sort((item1, item2) => item1?.[key].localeCompare(item2?.[key]));
};

const convertStringToNumber = (num: string) => {
  if (!num?.length) return "";
  return num.indexOf(".") !== -1 ? parseFloat(num) : parseInt(num);
};

const sanitizeText = (inputString: string) => {
  // Define a regular expression pattern to match special characters
  const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;
  // Use the replace method to remove special characters
  const sanitizedString = inputString.replace(specialCharsRegex, "");
  return sanitizedString;
};

const setTitleNumberInput = () => {
  const numberInputs = document.querySelectorAll("input.theme-ip, select.theme-ip, p.theme-ip") as NodeListOf<HTMLInputElement>;
  console.log(numberInputs);
  numberInputs?.forEach((numberInput) => {
    const labelParent = numberInput.closest("label");
    if (labelParent) {
      // numberInput.parentElement.title = "Enter Full Number.";
      const spanElement = labelParent.querySelector(".form-label");
      if (spanElement && !labelParent.title) {

        if(spanElement.textContent && spanElement.textContent.startsWith("Counterion")) {
          labelParent.title = "The ion that accompanies an ionic species\n" +
            "in order to maintain electric neutrality"
        } else if(spanElement.textContent && spanElement.textContent.startsWith("Owner")) {
          labelParent.title = "Physical Location of the Asset"
        } else if(spanElement.textContent && spanElement.textContent.startsWith("Tag Number")) {
          labelParent.title = "Equipment number at the Site"
        } else if(spanElement.textContent && spanElement.textContent.startsWith("Location")) {
          labelParent.title = "Physical Location of the Asset"
        } else if(spanElement.textContent && spanElement.textContent.startsWith("Material of Construction")) {
          labelParent.title = "Wetted Material, e.g. a Glass Lined Stainless Steel Reactor is \"GLASS\""
        } else {
            // @ts-ignore
            labelParent.title = spanElement.textContent;
          }
      }
      }
  });
};

const sortDescByField = function(arr: Array<any>, fieldName: string) {
  return arr.slice().sort((a: any, b: any) => {


    const valueA = a[fieldName];
    const valueB = b[fieldName];

    // Check if the values are numbers
    if (typeof valueA === "number" && typeof valueB === "number") {
      return valueB - valueA;
    }

    // Handle cases where the field value is missing or undefined
    if (valueA === undefined || valueA === null) {
      return 1; // Move objects with missing field value to the end
    }
    if (valueB === undefined || valueB === null) {
      return -1; // Move objects with missing field value to the end
    }

    const nameA = a[fieldName].toUpperCase(); // Convert to uppercase for case-insensitive comparison
    const nameB = b[fieldName].toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
const sortAscByField = function(arr: Array<any>, fieldName: string) {
  return arr.slice().sort((a: any, b: any) => {

    const valueA = a[fieldName];
    const valueB = b[fieldName];

    // Check if the values are numbers
    if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    }

    // Handle cases where the field value is missing or undefined
    if (valueA === undefined || valueA === null) {
      return 1; // Move objects with missing field value to the end
    }
    if (valueB === undefined || valueB === null) {
      return -1; // Move objects with missing field value to the end
    }

    const nameA = a[fieldName].toUpperCase(); // Convert to uppercase for case-insensitive comparison
    const nameB = b[fieldName].toUpperCase();

    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  });
};

const sortByField = function(arr: Array<any>, fieldName: string, sortOrder: "asc" | "desc") {
  const multiplier = sortOrder === "asc" ? 1 : -1;

  return arr.slice().sort((a: any, b: any) => {
    const valueA = a[fieldName];
    const valueB = b[fieldName];

    // Handle cases where the field value is missing or undefined
    if (valueA == null) return 1 * multiplier;
    if (valueB == null) return -1 * multiplier;

    if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * multiplier;
    }

    const strA = String(valueA).toUpperCase();
    const strB = String(valueB).toUpperCase();

    return (strA.localeCompare(strB) * multiplier);
  });
};

const toCamelCase = (input: string) => {
  if (typeof input !== "string") {
    return input;
  }
  const words = input.split("_");
  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const flattenArray = (arr: any, parentKey = '') => {
  const result: string[] = [];

  arr.forEach((item: any) => {
      if (typeof item === 'string') {
          result.push(parentKey ? `${parentKey}-${item}` : item);
      } else if (Array.isArray(item)) {
          // Recursively flatten any nested arrays
          result.push(...flattenArray(item, parentKey));
      } else if (typeof item === 'object') {
          for (const key in item) {
              const subArray = item[key];
              result.push(...flattenArray(subArray, parentKey ? `${parentKey}-${key}` : key));
          }
      }
  });

  return result;
}

export {
  cleanFileName,
  findFile,
  getUpdatedFiles,
  getLabel,
  limitDigits,
  uniqueItemsByKey,
  sortItemsByKey,
  sanitizeText,
  handleFilesFormat,
  handleScientificNotation,
  convertStringToNumber,
  setTitleNumberInput,
  sortDescByField,
  sortAscByField,
  sortByField,
  toCamelCase,
  flattenArray
};

//future use

// const getLabel = (arr: any[], key: string) => {
//   let items: any = [];
//   switch (key) {
//     case "type":
//       const res = EquipmentOwners.filter(x => arr.some(y => y === x.value));
//       return res
//       // return res.map(x => x.label);
//       break;
//     case "owners":
//       break;
//     default:
//       console.log('');
//   }
// }