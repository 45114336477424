const PERMISSION_MESSAGE = "It seems like you don't have the necessary permissions to access this page. \
    If you believe this is an error, please contact your administrator or support team for assistance. <br /> <br /> \
    If you think you should have access to this feature, please reach out to the system administrator to review and adjust your permissions. <br /> <br /> \
    <p>Thank you for your understanding.</p>";

const MULTIPLE_PERMISSIONS_MESSAGE = "Please clarify group permissions. You can not be part of both internal and external groups at the same time."

const NEW_MATERIAL_SUCCESS_MESSAGE = "Material data saved successfully.";
const UPDATE_MATERIAL_SUCCESS_MESSAGE = "Material data updated successfully.";
const NEW_EQUIPMENT_SUCCESS_MESSAGE = "Equipment data saved successfully.";
const UPDATE_EQUIPMENT_SUCCESS_MESSAGE = "Equipment data updated successfully.";
const NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE = "Equipment models data saved successfully.";
const UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE = "Equipment models data updated successfully.";
const DUPLICATE_FILE_ERROR_MESSAGE = "Please remove duplicate files.";
const MAX_FILESIZE_ERROR_MESSAGE = "Max file size exceed. Please try again with valid files.";
const ERROR_MESSAGE = "Oops! something went wrong.";

export {
    PERMISSION_MESSAGE,
    MULTIPLE_PERMISSIONS_MESSAGE,
    NEW_MATERIAL_SUCCESS_MESSAGE,
    UPDATE_MATERIAL_SUCCESS_MESSAGE,
    NEW_EQUIPMENT_SUCCESS_MESSAGE,
    UPDATE_EQUIPMENT_SUCCESS_MESSAGE,
    NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE,
    UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE,
    DUPLICATE_FILE_ERROR_MESSAGE,
    MAX_FILESIZE_ERROR_MESSAGE,
    ERROR_MESSAGE
}

export const MATERIAL_DASHBOARD_PAGE_TITLE = "Material Database";
export const MATERIAL_SCREENING_PAGE_TITLE = "All Materials Screening";
export const MATERIAL_FIND_PAGE_TITLE = "Find Material";
export const ADD_MATERIAL_PAGE_TITLE = "Add New Material";
export const VIEW_MATERIAL_PAGE_TITLE = "View Material";
export const UPDATE_MATERIAL_PAGE_TITLE = "Update Material";
export const MATERIAL_SOLUBILITY_PAGE_TITLE = "Material Solubility Models";
export const EQUIPMENT_MODELS_PAGE_TITLE = "Equipment Models";
export const EQUIPMENTS_DASHBOARD_PAGE_TITLE = "Equipment Database";
export const EQUIPMENTS_SCREENING_PAGE_TITLE = "All Equipments Screening";
export const EQUIPMENTS_FIND_PAGE_TITLE = "Find Equipment";
export const EQUIPMENTS_VIEW_PAGE_TITLE = "View Equipment";
export const SOLID_PHASE_PAGE_TITLE = "Solid Properties";
export const LIQUID_PHASE_PAGE_TITLE = "Liquid Properties";
export const GAS_PHASE_PAGE_TITLE = "Gas Properties";
export const APPS_AND_MODELS_PAGE_TITLE = "Apps & Models";
export const APPS_AND_MODELS_CRYSTALLIZATION_PAGE_TITLE = "Apps & Models - Crystallization";

export const layoutTitles = {
    dashboard: "Dashboard",
    materialsDashboard: "Materials",
    addMaterial: "Add Material",
    findMaterial: "Material Find",
    materialScreening: "Material Screening",
    updateMaterial: "Update Material",
    viewMaterial: "Material View",
    materialSolidSolubility: "Solid Properties - Solubility",
    materialSpectralInfo: "Solid Properties - Spectral Information",
    materialSolubility: "Material Solubility Models",
    modelsDashboard: "Apps & Models",
    equipmentDashboard: "Equipments",
    addEquipment: "Equipment New",
    updateEquipment: "Equipment Update",
    equipmentScreening: "Equipment Screening",
    profile: "Profile",
    appsCrystallization: "Apps & Models - Crystallization",
}

export const breadCrumbItems = {
    [layoutTitles.dashboard]: [],
    [layoutTitles.materialsDashboard]: [{ label: "Home", path: "/" }, { label: "Materials", path: "#" }],
    [layoutTitles.materialScreening]: [{ label: "Home", path: "/" }, { label: "Materials", path: "/materials" }, { label: "Material Screening", path: "#" }],
    [layoutTitles.findMaterial]: [{ label: "Home", path: "/" }, { label: "Materials", path: "/materials" }, { label: "Find Material", path: "#" }],
    [layoutTitles.viewMaterial]: [{ label: "Home", path: "/" }, { label: "Materials", path: "/materials" }, { label: "Material Screening", path: "/materials/list" }, { label: "View Material", path: "#" }],
    [layoutTitles.modelsDashboard]: [{ label: "Home", path: "/" }, { label: "Apps & Models", path: "#" }],
    [layoutTitles.equipmentDashboard]: [{ label: "Home", path: "/" }, { label: "Equipments", path: "#" }],
    [layoutTitles.equipmentScreening]: [{ label: "Home", path: "/" }, { label: "Equipments", path: "/equipments" }, { label: "Equipment Screening", path: "#" }],
    [layoutTitles.profile]: [{ label: "Home", path: "/" }, { label: "Profile", path: "#" }],
    [layoutTitles.appsCrystallization]: [{ label: "Home", path: "/" }, { label: "Apps & Models", path: "/apps-and-models" }, { label: "Crystallization", path: "#" }],
}
