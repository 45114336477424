import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../../components/layout";
import BackButton from "../../../components/common/BackButton";

// modals
import CancelConfirmModal from "../../../components/modals/CancelFormModal";
import HelpModal from "../../../components/modals/HelpModal";

// tabs
import {
  General, Aeration, Compressibility, Densities, DrugInformation, FlowEnergy,
  Hygroscopicity, Morphologi, Permeability, PSD, SEM, ShearCell, ThermalAnalysis,
} from "../../../components/material/tabs";

// services
import FileService from "../../../services/fileService";
import MaterialService from "../../../services/materialService";

// redux actions
import { materialAction } from "../../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../../redux/actions/alertActions";

// props
import { RootState } from "../../../redux/store";
import { SolidPhaseFormStatusProps } from "../../../types/materialsTypes";

// helpers
import {
  getAttachments, solidInitialData, solidFormNumberFields,
  checkDuplicateFiles, solidPhaseFormStatus, getSolidFormStatus,
  isObjectEmpty, removeEmpty,
} from "../../../utils/materialHelper";
import {
  findFile,
  getUpdatedFiles,
  convertStringToNumber,
  setTitleNumberInput,
} from "../../../utils/common";
import { MaterialSolidPhaseHelp } from "../../../utils/helpContent";
import attachments from "../../../components/material/tabs/Attachments";
import { at } from "lodash";
import { getValue } from "@testing-library/user-event/dist/utils";
import AppTabs, { AppTabProps } from "../../../components/common/AppTabs";
import BodyHeader from "../../../components/layout/BodyHeader";
import { ButtonIcons, ButtonProps, ButtonTypes } from "../../../components/common/Button";
import RenderIf from "../../../components/common/RenderIf";
import { SOLID_PHASE_PAGE_TITLE } from "../../../utils/constant";
import ImpurityResiduals from "../../../components/material/tabs/phase/solid/ImpurityResiduals";


const MaterialSolidPhase = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // redux data
  const material: any = useSelector((state: RootState) => state.material.item);

  // form
  const methods = useForm({ defaultValues: solidInitialData(material) });

  // states
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [materialData, setMaterialData] = useState<any>();
  const [savedData, setSavedData] = useState<SolidPhaseFormStatusProps>(solidPhaseFormStatus);

  useEffect(() => {
    methods.watch();
  });

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener("beforeunload", refreshData);
    return () => {
      window.removeEventListener("beforeunload", refreshData);
    };
  }, []);

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
  }, [tabIndex, material]);

  useEffect(() => {
    if (material?.solid) {
      (async () => {
        setSavedData(await getSolidFormStatus(material));
      })();
    }
    // params?.id ? setSavedData(checkSavedData(material?.solid)) : ''
  }, [material, tabIndex]);

  useEffect(() => {
    if (!material) {
      history.push("/materials");
      return;
    }
    setMaterialData(material);
  }, [material]);

  const uploadFile = async (file: any) => {
    const attachments = getAttachments(material?.solid ?? {}, methods.getValues());
    let newAttachment = await findFile(attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: "PUT",
          body: blob,
        });
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadFileAPI = async (attachmentList: any[]) => {
    if (attachmentList.length) {
      // setLoading(true); // enable loading
      const filesResponse = await FileService.create("/files/upload", {
        id: materialData.id,
        file_for: "Material",
        files: attachmentList.map((attachment: any) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
      });

      if (filesResponse?.status === 200) {
        const filesResult = filesResponse?.data;
        // setLoading(true); // enable loading
        if (filesResult?.body?.files)
          return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
            .then(async (res) => {
              // update material object
              filesResult.body.files.map((file: any) => delete file.signedUrl);

              const r = filesResult?.body?.files?.reduce((e: any, t: any) => {
                switch (t.category) {
                  case "COSMO File":
                    e.general = [...e?.general, t];
                    break;
                  case "Crystal Structure":
                    e.general = [...e?.general, t];
                    break;
                  case "XRPD":
                    e.general = [...e?.general, t];
                    break;
                  case "Aeration":
                    e.aeration = [...e?.aeration, t];
                    break;
                  case "Compressibility":
                    e.compressibility = [...e?.compressibility, t];
                    break;
                  case "ADME":
                    e.drug_info.adme = [...e?.drug_info?.adme, t];
                    break;
                  case "Clinical Trials Info":
                    e.drug_info.clinical_trials = [...e?.drug_info?.clinical_trials, t];
                    break;
                  case "Indication":
                    e.drug_info.indication = [...e?.drug_info?.indication, t];
                    break;
                  case "Toxicity Data":
                    e.drug_info.toxicity = [...e?.drug_info?.toxicity, t];
                    break;
                  case "Flow Energy":
                    e.flow_energy = [...e?.flow_energy, t];
                    break;
                  case "Isotherm Curve":
                    e.hygroscopicity.isothermCurve = [...e?.hygroscopicity?.isothermCurve, t];
                    break;
                  case "Hygroscopicity 2":
                    e.hygroscopicity.hygroscopicity2 = [...e?.hygroscopicity?.hygroscopicity2, t];
                    break;
                  case "Morphologi G3":
                    e.morphologi = [...e?.morphologi, t];
                    break;
                  case "Permeability":
                    e.permeability = [...e?.permeability, t];
                    break;
                  case "PSD":
                  case "Histogram":
                    e.psd = [...e?.psd, t];
                    break;
                  case "SEM":
                  case "Microscopy":
                    e.sem = [...e?.sem, t];
                    break;
                  case "Shear Cell":
                    e.shear_cell = [...e?.shear_cell, t];
                    break;
                  case "Heat Curve":
                    e.thermal.heatCurveDsc = [...e?.thermal?.heatCurveDsc, t];
                    break;
                  case "SOP File":
                    e.thermal.sopFileDsc = [...e?.thermal?.sopFileDsc, t];
                    break;
                  case "Heat Curve 2":
                    e.thermal.heatCurveTga = [...e?.thermal?.heatCurveTga, t];
                    break;
                  case "SOP File 2":
                    e.thermal.sopFileTga = [...e?.thermal?.sopFileTga, t];
                    break;
                  case "Method Number":
                    e.densities.method_number = [...e?.densities?.method_number, t];
                    break;
                  case "SOP":
                    e.densities.sop = [...e?.densities?.sop, t];
                    break;
                }
                return e;
              }, {
                general: [],
                aeration: [],
                compressibility: [],
                drug_info: {
                  adme: [],
                  clinical_trials: [],
                  indication: [],
                  toxicity: [],
                },
                densities: {
                  sop: [],
                },
                flow_energy: [],
                hygroscopicity: {
                  isothermCurve: [],
                  hygroscopicity2: [],
                },
                morphologi: [],
                permeability: [],
                psd: [],
                sem: [],
                shear_cell: [],
                thermal: {
                  heatCurveDsc: [],
                  sopFileDsc: [],
                  heatCurveTga: [],
                  sopFileTga: [],
                },
              });


              const attachmentObj = {
                general: {
                  attachments: await getUpdatedFiles([...material?.solid?.general?.attachments ?? [], ...r.general], methods.getValues("general.attachments") || [], !0),
                },
                aeration: {
                  attachments: await getUpdatedFiles([...material?.solid?.aeration?.attachments ?? [], ...r.aeration], methods.getValues("aeration.attachments") || [], !0),
                },
                compressibility: {
                  attachments: await getUpdatedFiles([...material?.solid?.compressibility?.attachments ?? [], ...r.compressibility], methods.getValues("compressibility.attachments") || [], !0),
                },
                drug_info: {
                  attachments: {
                    adme: await getUpdatedFiles([...material?.solid?.drug_info?.attachments?.adme ?? [], ...r.drug_info.adme], methods.getValues("drug_info.attachments.adme") || [], !0),
                    clinical_trials: await getUpdatedFiles([...material?.solid?.drug_info?.attachments?.clinical_trials ?? [], ...r.drug_info.clinical_trials], methods.getValues("drug_info.attachments.clinical_trials") || [], !0),
                    indication: await getUpdatedFiles([...material?.solid?.drug_info?.attachments?.indication ?? [], ...r.drug_info.indication], methods.getValues("drug_info.attachments.indication") || [], !0),
                    toxicity: await getUpdatedFiles([...material?.solid?.drug_info?.attachments?.toxicity ?? [], ...r.drug_info.toxicity], methods.getValues("drug_info.attachments.toxicity") || [], !0),
                  },
                },
                densities: {
                  attachments: {
                    sop: await getUpdatedFiles(
                      [...material?.solid?.densities?.attachments?.sop ?? [],
                        ...r.densities.sop],
                      methods.getValues("densities.attachments.sop") || [], !0),
                  },
                },
                flow_energy: {
                  attachments: await getUpdatedFiles([...material?.solid?.flow_energy?.attachments ?? [], ...r.flow_energy], methods.getValues("flow_energy.attachments") || [], !0),
                },
                hygroscopicity: {
                  attachments: {
                    isothermCurve: await getUpdatedFiles([...material?.solid?.hygroscopicity?.attachments?.isothermCurve ?? [], ...r.hygroscopicity?.isothermCurve], methods.getValues("hygroscopicity.attachments.isothermCurve") || [], !0),
                    hygroscopicity2: await getUpdatedFiles([...material?.solid?.hygroscopicity?.attachments?.hygroscopicity2 ?? [], ...r.hygroscopicity?.hygroscopicity2], methods.getValues("hygroscopicity.attachments.hygroscopicity2") || [], !0),
                  },
                },
                morphologi: {
                  attachments: await getUpdatedFiles([...material?.solid?.morphologi?.attachments ?? [], ...r.morphologi], methods.getValues("morphologi.attachments") || [], !0),
                },
                permeability: {
                  attachments: await getUpdatedFiles([...material?.solid?.permeability?.attachments ?? [], ...r.permeability], methods.getValues("permeability.attachments") || [], !0),
                },
                psd: {
                  attachments: await getUpdatedFiles([...material?.solid?.psd?.attachments ?? [], ...r.psd], methods.getValues("psd.attachments") || [], !0),
                },
                sem: {
                  attachments: await getUpdatedFiles([...material?.solid?.sem?.attachments ?? [], ...r.sem], methods.getValues("sem.attachments") || [], !0),
                },
                shear_cell: {
                  attachments: await getUpdatedFiles([...material?.solid?.shear_cell?.attachments ?? [], ...r.shear_cell], methods.getValues("shear_cell.attachments") || [], !0),
                },
                thermal: {
                  attachments: {
                    heatCurveDsc: await getUpdatedFiles([...material?.solid?.thermal?.attachments?.heatCurveDsc ?? [], ...r.thermal.heatCurveDsc], methods.getValues("thermal.attachments.heatCurveDsc") || [], !0),
                    sopFileDsc: await getUpdatedFiles([...material?.solid?.thermal?.attachments?.sopFileDsc ?? [], ...r.thermal.sopFileDsc], methods.getValues("thermal.attachments.sopFileDsc") || [], !0),
                    heatCurveTga: await getUpdatedFiles([...material?.solid?.thermal?.attachments?.heatCurveTga ?? [], ...r.thermal.heatCurveTga], methods.getValues("thermal.attachments.heatCurveTga") || [], !0),
                    sopFileTga: await getUpdatedFiles([...material?.solid?.thermal?.attachments?.sopFileTga ?? [], ...r.thermal.sopFileTga], methods.getValues("thermal.attachments.sopFileTga") || [], !0),
                  },
                },
              };

              Array.from(
                Object.keys(attachmentObj),
              ).forEach((key) => {
                // @ts-ignore
                methods.setValue(`${key}.attachments`, attachmentObj[key].attachments);
              });


              // const newPayload = {
              //
              //   ...materialData,
              //   id: materialData.id,
              //   uid: auth?.claims?.uid,
              //   solid: {
              //     general: {
              //       ...methods.getValues("general"),
              //     },
              //     aeration: {
              //       ...methods.getValues("aeration"),
              //     },
              //     compressibility: { ...methods.getValues("compressibility") },
              //     densities: { ...methods.getValues("densities") },
              //     drug_info: { ...methods.getValues("drug_info") },
              //     flow_energy: { ...methods.getValues("flow_energy") },
              //     hygroscopicity: { ...methods.getValues("hygroscopicity") },
              //     morphologi: { ...methods.getValues("morphologi") },
              //     permeability: { ...methods.getValues("permeability") },
              //     psd: { ...methods.getValues("psd") },
              //     sem: { ...methods.getValues("sem") },
              //     shear_cell: { ...methods.getValues("shear_cell") },
              //     thermal: { ...methods.getValues("thermal") },
              //   },
              // };

              const newPayload = constructPayload();
              const formPayload = {
                ...materialData,
                id: materialData.id,
                uid: auth?.claims?.uid,
              };
              if (Array.from(Object.keys(newPayload)).length) {
                formPayload["solid"] = newPayload;
              }
              const materialResponse = await MaterialService.update(formPayload);
              Array.from(
                Object.keys(attachmentObj),
              ).forEach((key) => {
                // @ts-ignore
                console.log(`${key}.attachments`, attachmentObj[key]);
                // @ts-ignore
                methods.setValue(`${key}.attachments`, attachmentObj[key].attachments);
              });
              // setLoading(false); // disble loading
              if (materialResponse?.status === 200) {
                dispatch(materialAction(materialResponse?.data?.body));
                handleSubFormStatus(materialResponse?.data?.body?.solid);
                // methods.reset(methods.getValues(), {
                //   keepValues: true, keepIsValid: true,
                // });
                return true;
              }
              return false;
            })
            .catch(() => {
              return false;
            });
      }
      return false;
    }
    return true;
  };

  const apiRequest = async (newAttachments: any[]) => {
    if (newAttachments?.length) {
      return await uploadFileAPI(newAttachments.filter((attachment: any) => !attachment.isUpload));
    }

    const attachmentObj = {
      general: {
        attachments: await getUpdatedFiles(material?.solid?.general?.attachments || [], methods.getValues("general.attachments") || [], !1),
      },
      aeration: {
        attachments: await getUpdatedFiles(material?.solid?.aeration?.attachments || [], methods.getValues("aeration.attachments") || [], !1),
      },
      compressibility: {
        attachments: await getUpdatedFiles(material?.solid?.compressibility?.attachments || [], methods.getValues("compressibility.attachments") || [], !1),
      },
      drug_info: {
        attachments: {
          adme:
            await getUpdatedFiles(material?.solid?.drug_info?.attachments?.adme || [], methods.getValues("drug_info.attachments.adme") || [], !1),
          clinical_trials:
            await getUpdatedFiles(material?.solid?.drug_info?.attachments?.clinical_trials || [], methods.getValues("drug_info.attachments.clinical_trials") || [], !1),
          indication:
            await getUpdatedFiles(material?.solid?.drug_info?.attachments?.indication || [], methods.getValues("drug_info.attachments.indication") || [], !1),
          toxicity:
            await getUpdatedFiles(material?.solid?.drug_info?.attachments?.toxicity || [], methods.getValues("drug_info.attachments.toxicity") || [], !1),
        },
      },
      densities: {
        attachments: {
          sop:
          // @ts-ignore
            await getUpdatedFiles(material?.solid?.densities?.attachments?.toxicity || [], methods.getValues("densities.attachments.toxicity") || [], !1),
        },
      },
      flow_energy: {
        attachments: await getUpdatedFiles(material?.solid?.flow_energy?.attachments || [], methods.getValues("flow_energy.attachments") || [], !1),
      },
      hygroscopicity: {
        attachments: {
          isothermCurve:
            await getUpdatedFiles(material?.solid?.hygroscopicity?.attachments?.isothermCurve || [], methods.getValues("hygroscopicity.attachments.isothermCurve") || [], !1),
          hygroscopicity2:
            await getUpdatedFiles(material?.solid?.hygroscopicity?.attachments?.hygroscopicity2 || [], methods.getValues("hygroscopicity.attachments.hygroscopicity2") || [], !1),
        },
      },
      morphologi: {
        attachments: await getUpdatedFiles(material?.solid?.morphologi?.attachments || [], methods.getValues("morphologi.attachments") || [], !1),
      },
      permeability: {
        attachments: await getUpdatedFiles(material?.solid?.permeability?.attachments || [], methods.getValues("permeability.attachments") || [], !1),
      },
      psd: {
        attachments: await getUpdatedFiles(material?.solid?.psd?.attachments || [], methods.getValues("psd.attachments") || [], !1),
      },
      sem: {
        attachments: await getUpdatedFiles(material?.solid?.sem?.attachments || [], methods.getValues("sem.attachments") || [], !1),
      },
      shear_cell: {
        attachments: await getUpdatedFiles(material?.solid?.shear_cell?.attachments || [], methods.getValues("shear_cell.attachments") || [], !1),
      },
      thermal: {
        attachments: {
          heatCurveDsc:
            await getUpdatedFiles(material?.solid?.thermal?.attachments?.heatCurveDsc || [], methods.getValues("thermal.attachments.heatCurveDsc") || [], !1),
          sopFileDsc:
            await getUpdatedFiles(material?.solid?.thermal?.attachments?.sopFileDsc || [], methods.getValues("thermal.attachments.sopFileDsc") || [], !1),

          heatCurveTga:
            await getUpdatedFiles(material?.solid?.thermal?.attachments?.heatCurveTga || [], methods.getValues("thermal.attachments.heatCurveTga") || [], !1),

          sopFileTga:
            await getUpdatedFiles(material?.solid?.thermal?.attachments?.sopFileTga || [], methods.getValues("thermal.attachments.sopFileTga") || [], !1),

        },
      },
    };

    Array.from(
      Object.keys(attachmentObj),
    ).forEach((key) => {
      // @ts-ignore
      methods.setValue(`${key}.attachments`, attachmentObj[key].attachments);
    });

    // const newPayload = {
    //
    //   ...materialData,
    //   id: materialData.id,
    //   uid: auth?.claims?.uid,
    //   solid: {
    //     general: {
    //       ...methods.getValues("general"),
    //     },
    //     aeration: {
    //       ...methods.getValues("aeration"),
    //     },
    //     compressibility: { ...methods.getValues("compressibility") },
    //     densities: { ...methods.getValues("densities") },
    //     drug_info: { ...methods.getValues("drug_info") },
    //     flow_energy: { ...methods.getValues("flow_energy") },
    //     hygroscopicity: { ...methods.getValues("hygroscopicity") },
    //     morphologi: { ...methods.getValues("morphologi") },
    //     permeability: { ...methods.getValues("permeability") },
    //     psd: { ...methods.getValues("psd") },
    //     sem: { ...methods.getValues("sem") },
    //     shear_cell: { ...methods.getValues("shear_cell") },
    //     thermal: { ...methods.getValues("thermal") },
    //   },
    // };
    //
    // console.log("Payload is ", payload);
    // console.log("New Payload is ", newPayload);
    const newPayload = constructPayload();
    const formPayload = {
      ...materialData,
      id: materialData.id,
      uid: auth?.claims?.uid,
    };
    if (Object.keys(newPayload).length) {
      formPayload["solid"] = newPayload;
    }
    const res = await MaterialService.update(formPayload);
    if (res?.status === 409) {
      return false;
    } else if (res?.status === 200) {
      dispatch(materialAction(res?.data?.body));
      handleSubFormStatus(res?.data?.body?.solid);
      methods.reset(methods.getValues(), { keepValues: true, keepIsValid: true });
      return true;
    }
    return false;
  };

  const handleSubFormStatus = async (solid: any) => {
    setSavedData({
      ...solidPhaseFormStatus,
      ...Object.keys(await removeEmpty(solid)).reduce((e: any, t: string) => {
        e[t] = true;
        return e;
      }, {}),
    });
  };

  const validateForm = async () => {
    // GET attachmented files
    const attachments = getAttachments(material?.solid ?? {}, methods.getValues());

    if (attachments?.length) {
      // Check invalid files.
      const invalidateFiles = attachments.find((attachment: any) => attachment.error);
      if (invalidateFiles) {
        dispatch(alertOpenAction("Max file size exceed.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }

      // Check duplicate files in same category.
      const isDuplicate = await (await checkDuplicateFiles(attachments))?.map((item: any) => item.isDuplicate).includes(true);
      if (isDuplicate) {
        dispatch(alertOpenAction("Please remove duplicate files.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }

    }

    if (!material?.solid) {
      let formData = { ...methods.control._formValues };
      const keysToExclude = ["material_name", "vendor_name", "lot_number"];
      formData = Object.fromEntries(
        Object.entries(formData).filter(([key]) => !keysToExclude.includes(key)),
      );
      let cleanSubForms = await isObjectEmpty(formData);
      if (cleanSubForms) {
        dispatch(alertOpenAction("Please add some data first.", "error"));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
    }
    return true;
  };

  function constructPayload() {
    const newPayload: any = {};
    const formValue: any = methods.getValues();
    Array.from(
      Object.keys(formValue),
    ).forEach(key => {
      // @ts-ignore
      const innerObj = formValue[key];
      Array.from(
        Object.keys(innerObj),
      ).forEach((innerKey) => {
        const val = innerObj[innerKey];
        if (innerKey === "attachments") {
          if (Array.isArray(val)) {
            if (val.length === 0)
              delete innerObj["attachments"];
          } else {
            Array.from(
              Object.keys(val),
            ).forEach((attachKey) => {
              const attachVal = innerObj["attachments"][attachKey];
              if (attachVal === null || attachVal === undefined || attachVal === "")
                delete innerObj["attachments"][attachKey];
              else if (Array.isArray(attachVal) && Array.from(Object.keys(attachVal)).length === 0)
                delete innerObj["attachments"][attachKey];
            });
            if (Array.from(Object.keys(val)).length === 0)
              delete innerObj["attachments"];
          }
        }
        if (val === null || val === undefined || val === "") {
          delete innerObj[innerKey];
        }
      });
      if (Array.from(Object.keys(JSON.parse(JSON.stringify(innerObj)))).length !== 0) {
        newPayload[key] = innerObj;
      }
    });
    delete newPayload["material_name"];
    delete newPayload["vendor_name"];
    delete newPayload["lot_number"];
    return newPayload;
  }

  // method to submit solid form.
  const onSubmit: SubmitHandler<any> = async () => {
    if (await validateForm()) {
      const newAttachments = getAttachments(material?.solid || {}, methods.getValues());
      setLoading(true); // enable loading
      const apiResponse = await apiRequest(newAttachments);
      setLoading(false); // disable loading
      if (apiResponse) {
        dispatch(alertOpenAction(`Phase Data ${params?.id ? "updated" : "added"} successfully.`, "success"));
      } else {
        dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // cancel modal buttons
  const saveMaterial = async () => {
    if (materialData) {
      setOpen(false);
      if (await validateForm()) {
        const newAttachments = getAttachments(material?.solid ?? {}, methods.getValues());
        setLoading(true); // enable loading
        const apiResponse = await apiRequest(newAttachments);
        setLoading(false); // disable loading
        if (apiResponse) {
          dispatch(alertOpenAction(`Phase Data ${params?.id ? "updated" : "added"} successfully.`, "success"));
          params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
        } else {
          dispatch(alertOpenAction("Oops! something went wrong.", "error"));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction("No data updated to save.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  const dontSave = () => {
    setOpen(false);
    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
  };

  const tabs : AppTabProps[]=[
    {
      title:"#",
      dataCy:"general-tab",
      className:`tab-link ${savedData?.general ? "done" : ""} `,
      component: <General material={material} dispatch={dispatch} />
    },
    {
      title:"Aeration",
      dataCy:"aeration-tab",
      className:`tab-link ${savedData?.aeration ? "done" : ""} `,
      component: <Aeration  material={material} dispatch={dispatch} />
    },
    {
      title:"Compressibility",
      dataCy:"compressibility-tab",
      className:`tab-link ${savedData?.compressibility ? "done" : ""} `,
      component: <Compressibility  material={material} dispatch={dispatch} />
    },
    {
      title:"Densities",
      dataCy:"densities-tab",
      className:`tab-link ${savedData?.densities ? "done" : ""} `,
      component: <Densities />
    },
    {
      title:"Drug Info",
      dataCy:"druginfo-tab",
      className:`tab-link ${savedData?.drug_info ? "done" : ""} `,
      component: <DrugInformation material={material} dispatch={dispatch} />
    },
    {
      title:"Flow Energy",
      dataCy:"flowenergy-tab",
      className:`tab-link ${savedData?.flow_energy ? "done" : ""} `,
      component: <FlowEnergy material={material} dispatch={dispatch} />
    },
    {
      title:"Hygroscopicity",
      dataCy:"hygroscopicity-tab",
      className:`tab-link ${savedData?.hygroscopicity ? "done" : ""} `,
      component: <Hygroscopicity material={material} dispatch={dispatch} />
    },
    {
      title:"Morphologi G3",
      dataCy:"morphologi-tab",
      className:`tab-link ${savedData?.morphologi ? "done" : ""} `,
      component: <Morphologi material={material} dispatch={dispatch} />
    },
    {
      title:"Permeability",
      dataCy:"permeability-tab",
      className:`tab-link ${savedData?.permeability ? "done" : ""} `,
      component: <Permeability material={material} dispatch={dispatch} />
    },
    {
      title:"PSD",
      dataCy:"psd-tab",
      className:`tab-link ${savedData?.psd ? "done" : ""} `,
      component: <PSD material={material} dispatch={dispatch} />
    },
    {
      title:"SEM",
      dataCy:"sem-tab",
      className:`tab-link ${savedData?.sem ? "done" : ""} `,
      component: <SEM material={material} dispatch={dispatch} />
    },
    {
      title:"Shear Cell",
      dataCy:"shearcell-tab",
      className:`tab-link ${savedData?.shear_cell ? "done" : ""} `,
      component: <ShearCell material={material} dispatch={dispatch} />
    },
    {
      title:"Thermal",
      dataCy:"thermal-tab",
      className:`tab-link ${savedData?.thermal ? "done" : ""} `,
      component: <ThermalAnalysis material={material} dispatch={dispatch} />
    },
    {
      title:"Impurity & Residuals",
      dataCy:"impurity-residuals-tab",
      className:`tab-link ${savedData?.thermal ? "done" : ""} `,
      component: <ImpurityResiduals material={material} dispatch={dispatch} />
    },

  ]
  // Header Buttons
  const headerButtons: ButtonProps[] = [
    {
      isIconButton: true,
      navigateTo: "",
      icon: ButtonIcons.SAVE,
      title: "Save",
      type: ButtonTypes.PRIMARY,
      onClick: onSubmit as any,
      hideForDesktop: false,
      disabled: loading,
      dataCy:"save-btn"
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.CROSS,
      title: "Close",
      type: ButtonTypes.SECONDARY,
      onClick: () => {
        if (
          methods.formState.isDirty ||
          Object.keys(methods.formState.dirtyFields).length
        ) {
          setOpen(true);
          return;
        }
        params?.id
          ? history.push(`/materials/${params?.id}/update`)
          : history.push(`/materials/new/`);
      },
      hideForDesktop: false,
      disabled: loading,
    },
    {
      isIconButton: true,
      navigateTo: "#",
      icon: ButtonIcons.HELP,
      title: "Help",
      type: ButtonTypes.SECONDARY,
      onClick: () => {
        setOpenHelp(true);
      },
      hideForDesktop: false,
      disabled: loading,
    },
  ];

  return (
    <Layout title="Material-Solid">
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            methods.handleSubmit(onSubmit);
          }}
          noValidate
        >
          <BodyHeader
            title={SOLID_PHASE_PAGE_TITLE}
            buttons={headerButtons}
            backButton={
              <BackButton
                onSubmit={saveMaterial}
                formState={methods.formState}
                material={material}
              />
            }
          />
          <div className="row">
            <div className="col-12">
              <div className="theme-card">
                <div className="body">
                  <AppTabs data={tabs} />
                </div>
                <RenderIf condition={loading}>
                  <div className="theme-loader show fixed">
                    <div className="loader-outer">
                      <div className="loader"></div>
                    </div>
                  </div>
               </RenderIf>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <CancelConfirmModal
        open={open}
        setOpen={setOpen}
        saveMaterial={saveMaterial}
        dontSave={dontSave}
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialSolidPhaseHelp.title}
        content={MaterialSolidPhaseHelp.content}
      />
    </Layout>
  );
};

export default MaterialSolidPhase;
