import Button, { ButtonIcons, ButtonProps, ButtonTypes } from "../common/Button";
import RenderIf from "../common/RenderIf";

interface BodyHeaderProps {
  title: string;
  buttons: ButtonProps[];
  showBackButton?: boolean;
  onBackClick?: () => void;
  backButton?: React.ReactElement;
}

const BodyHeader = (props: BodyHeaderProps) => {
  const { title, buttons, showBackButton = false, backButton } = props;

  return (
    <div className="sec-info control-head">
      <RenderIf condition={showBackButton}>
        <div className="back">
          <Button isIconButton onClick={props?.onBackClick} icon={ButtonIcons.ARROW_LEFT} title="Back" type={ButtonTypes.SECONDARY} />
        </div>
      </RenderIf>

      <RenderIf condition={backButton !== undefined}>
        <div className="back">{backButton}</div>
      </RenderIf>
      <div className="head">
        <h1 className="head-lg">{title}</h1>
      </div>
      <div className="right">
        {buttons.map((btnProps) => (
          <Button {...btnProps} />
        ))}
      </div>
    </div>
  );
};

export default BodyHeader;
