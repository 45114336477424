import React, { useEffect, useState } from "react";
import Button, { ButtonTypes } from "../../../../common/Button";
import { useForm, useFormContext } from "react-hook-form";
import MaterialService from "../../../../../services/materialService";
import AppSelect from "../../../../common/AppSelect";
import Select from "react-select";

interface ChemicalPurityType {
  impurity: string;
  method: string;
  value: number;
  unit: string;
}

interface ResidualSolventType {
  solvent: string;
  method: string;
  value: number;
  unit: string;
  testMethod: string;
}

interface HeavyMetalType {
  metal: string;
  method: string;
  value: number;
  unit: string;
  testMethod: string;
}

interface ImpurityResidualsType {
  testMethod: string;
  relatedElnExperiments: string;
  assay: string;
  water: string;
}

const ImpurityResiduals = (props: any) => {
  const { control } = useForm<any>({ defaultValues: props.initialData });
  4;
  const methods = useFormContext();

  const [residualSolventRows, setResidualSolventRows] = useState<
    ResidualSolventType[]
  >([{ solvent: "", method: "", value: 0, unit: "", testMethod: "" }]);
  const [heavyMetalRows, setHeavyMetalRows] = useState<HeavyMetalType[]>([
    { metal: "", method: "", value: 0, unit: "", testMethod: "" },
  ]);
  const [chemicalPurities, setChemicalPurities] = useState<
    ChemicalPurityType[]
  >([{ impurity: "", method: "", value: 0, unit: "" }]);

  const [testMethod, setTestMethod] = useState<string>("");
  const [relatedElnExperiments, setRelatedElnExperiments] =
    useState<string>("");
  const [assay, setAssay] = useState<string>("");
  const [water, setWater] = useState<string>("");

  const [solvents, setSolvents] = useState<any>([]);
  useEffect(() => {
    MaterialService.getSolvents().then((res) => {
      if (res.status === 200 && res.data.body.solvensts) {
        setSolvents(res.data.body.solvensts || []);
      }
    });
  }, []);

  const handleChemicalPurityInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updatedRows = chemicalPurities.map((row, i) =>
      i === index ? { ...row, [event.target.name]: event.target.value } : row
    );
    setChemicalPurities(updatedRows);
  };

  const handleResidualSolventChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updatedRows = residualSolventRows.map((row, i) =>
      i === index ? { ...row, [event.target.name]: event.target.value } : row
    );
    setResidualSolventRows(updatedRows);
  };

  const handleHeavyMetalChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updatedRows = heavyMetalRows.map((row, i) =>
      i === index ? { ...row, [event.target.name]: event.target.value } : row
    );
    setHeavyMetalRows(updatedRows);
  };

  const handleChemicalPurityAddRow = () => {
    setChemicalPurities([
      ...chemicalPurities,
      { impurity: "", method: "", value: 0, unit: "" },
    ]);
  };
  const addResidualSolventRow = () =>
    setResidualSolventRows([
      ...residualSolventRows,
      { solvent: "", method: "", value: 0, unit: "", testMethod: "" },
    ]);
  const addHeavyMetalRow = () =>
    setHeavyMetalRows([
      ...heavyMetalRows,
      { metal: "", method: "", value: 0, unit: "", testMethod: "" },
    ]);

  const handleChemicalPurityDeleteRow = (index: any) => {
    const updatedRows = chemicalPurities?.filter((row, i) => i !== index);
    setChemicalPurities(updatedRows);
  };
  const handleHeavyMetalDeleteRow = (index: any) => {
    const updatedRows = heavyMetalRows?.filter((row, i) => i !== index);
    setHeavyMetalRows(updatedRows);
  };
  const handleResidualSolventDeleteRow = (index: any) => {
    const updatedRows = residualSolventRows?.filter((row, i) => i !== index);
    setResidualSolventRows(updatedRows);
  };

  console.log(solvents);

  return (
    <div className="container  mx-auto p-4 space-y-8">
      {/* Chemical Purity Table */}
      <h2 className="text-xl font-semibold mb-4">Chemical Purity</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6 ">
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="material_name">
            <span className="form-label">Test Method</span>
          </label>
          <input
            type="text"
            name="testMethod"
            required={true}
            value={testMethod}
            onChange={(e) => setTestMethod(e.target.value)}
            className="theme-ip w-full"
          />
        </div>
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="material_name">
            <span className="form-label">Related ELN Experiments</span>
          </label>
          <input
            type="text"
            name="relatedElnExperiments"
            value={relatedElnExperiments}
            onChange={(e) => setRelatedElnExperiments(e.target.value)}
            className="theme-ip w-full"
          />
        </div>
      </div>
      <div className="mt-4">
        <Button
          title=" + Add Chemical Purity"
          navigateTo="# "
          isIconButton={false}
          onClick={handleChemicalPurityAddRow}
          type={ButtonTypes.PRIMARY}
          className="text-red-500 font-bold hover:text-red-700"
        />
      </div>

      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-2 py-2 border-b text-center">Impurity</th>
              <th className="px-2 py-2 border-b text-center">Method</th>
              <th className="px-2 py-2 border-b text-center">Value</th>
              <th className="px-2 py-2 border-b text-center">Unit</th>
              <th className="px-2 py-2 border-b text-center">Operate</th>
            </tr>
          </thead>
          <tbody>
            {chemicalPurities.map((row: ChemicalPurityType, index) => (
              <tr key={index} className="border-b">
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="impurity"
                      value={row.impurity}
                      onChange={(e) =>
                        handleChemicalPurityInputChange(index, e)
                      }
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="method"
                      value={row.method}
                      onChange={(e) =>
                        handleChemicalPurityInputChange(index, e)
                      }
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="number"
                      name="value"
                      value={row.value}
                      onChange={(e) =>
                        handleChemicalPurityInputChange(index, e)
                      }
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="unit"
                      value={row.unit}
                      onChange={(e) =>
                        handleChemicalPurityInputChange(index, e)
                      }
                      className="theme-ip w-full"
                    />
                  </div>
                </td>

                <td className="px-2 py-2 justify-center text-center">
                  <button
                    onClick={() => handleChemicalPurityDeleteRow(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6 ">
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="material_name">
            <span className="form-label">Assay (% w/w)</span>
          </label>
          <input
            type="text"
            name="assay"
            value={assay}
            onChange={(e) => setAssay(e.target.value)}
            className="theme-ip w-full"
          />
        </div>
        <div className="input-wrap w-full">
          <label className="ip-wrap" htmlFor="material_name">
            <span className="form-label">Water (% w/w)</span>
          </label>
          <input
            type="text"
            name="water"
            value={water}
            onChange={(e) => setWater(e.target.value)}
            className="theme-ip w-full"
          />
        </div>
      </div>
      <div className="divider"></div>

      {/* Residual Solvents Table */}

      <h2 className="text-xl font-semibold mb-4">Residual Solvents</h2>
      <div className="mt-4">
        <Button
          title="+ Add Residual Solvent          "
          navigateTo="# "
          isIconButton={false}
          onClick={addResidualSolventRow}
          type={ButtonTypes.PRIMARY}
          className="text-red-500 font-bold hover:text-red-700"
        />
      </div>

      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-2 py-2 border-b text-center">
                Residual Solvent
              </th>
              <th className="px-2 py-2 border-b text-center">Method</th>
              <th className="px-2 py-2 border-b text-center">Value</th>
              <th className="px-2 py-2 border-b text-center">Unit</th>
              <th className="px-2 py-2 border-b text-center">Test Method</th>
              <th className="px-2 py-2 border-b text-center">Operate</th>
            </tr>
          </thead>
          <tbody>
            {residualSolventRows.map((row: ResidualSolventType, index) => (
              <tr key={index} className="border-b">
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <div className="input-wrap select-outer">
                      <select
                        className="theme-ip"
                        name="solvent "
                        onChange={(e) => {
                          handleResidualSolventChange(index, e);
                        }}
                      >
                        <option value="">Select</option>
                        {solvents.map((solvent: any) => (
                          <option value={solvent.density}>
                            {solvent.material_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="method"
                      value={row.method}
                      onChange={(e) => handleResidualSolventChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="number"
                      name="value"
                      value={row.value}
                      onChange={(e) => handleResidualSolventChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="unit"
                      value={row.unit}
                      onChange={(e) => handleResidualSolventChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="testMethod"
                      value={row.testMethod}
                      onChange={(e) => handleResidualSolventChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>

                <td className="px-2 py-2 justify-center text-center">
                  <button
                    onClick={() => handleResidualSolventDeleteRow(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="divider"></div>

      {/* Heavy Metals Table */}

      <h2 className="text-xl font-semibold mb-4">Heavy Metals</h2>
      <div className="mt-4">
        <Button
          title="+ Add Heavy Metal          "
          navigateTo="#"
          isIconButton={false}
          onClick={addHeavyMetalRow}
          type={ButtonTypes.PRIMARY}
          className="text-red-500 font-bold hover:text-red-700"
        />
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: "300px" }}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-2 py-2 border-b text-center">Heavy Metal</th>
              <th className="px-2 py-2 border-b text-center">Method</th>
              <th className="px-2 py-2 border-b text-center">Value</th>
              <th className="px-2 py-2 border-b text-center">Unit</th>
              <th className="px-2 py-2 border-b text-center">Test Method</th>
              <th className="px-2 py-2 border-b text-center">Operate</th>
            </tr>
          </thead>
          <tbody>
            {heavyMetalRows.map((row: HeavyMetalType, index) => (
              <tr key={index} className="border-b">
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="solvent"
                      value={row.metal} //TODO: select
                      onChange={(e) => handleHeavyMetalChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="method"
                      value={row.method}
                      onChange={(e) => handleHeavyMetalChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="number"
                      name="value"
                      value={row.value}
                      onChange={(e) => handleHeavyMetalChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="unit"
                      value={row.unit}
                      onChange={(e) => handleHeavyMetalChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>
                <td className="px-2 py-2 justify-center">
                  <div className="input-wrap w-full">
                    <input
                      type="text"
                      name="testMethod"
                      value={row.testMethod}
                      onChange={(e) => handleHeavyMetalChange(index, e)}
                      className="theme-ip w-full"
                    />
                  </div>
                </td>

                <td className="px-2 py-2 justify-center text-center">
                  <button
                    onClick={() => handleHeavyMetalDeleteRow(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ImpurityResiduals;
