import React, { useEffect, useState } from "react";
import MaterialVisualizationTable from "../MaterialVisualizationTable";
import SecondaryLayout from "../../../components/layout/SecondaryLayout";
import Button, {
  ButtonIcons,
  ButtonTypes,
} from "../../../components/common/Button";
import MaterialService from "../../../services/materialService";
import { apiRoutes } from "../../../utils/apiRoutes";
import { useOktaAuth } from "@okta/okta-react";
import CustomLoader from "../../../components/common/CustomLoader";
import { useDispatch } from "react-redux";
import { alertCloseAction, alertOpenAction } from "../../../redux/actions";
import { getValueFromConcatenatedKey } from "../../../utils/materialKeyLabelHelper";
import BodyHeader from "../../../components/layout/BodyHeader";
import { Field, Material, parseFields } from "./utils";

export type MaterialVisualizationPropsType = {
  materialFields: string[];
  materialIds: string[];
  phase: string;
  required_fields: string[];
  required_attachments: any[];
};

const MaterialVisualize = () => {
  // init
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  const auth: any = authState ? authState?.accessToken : "";

  const materialVisualizationProps: MaterialVisualizationPropsType = JSON.parse(localStorage.getItem("materialVisualizationProps") || "");
  const initialFields: Field[] = parseFields([
    ...materialVisualizationProps.materialFields,
    ...materialVisualizationProps.required_fields,
    ...materialVisualizationProps.required_attachments,
  ]);

  // states
  const [materials, setMaterials] = useState<Material[]>([]);
  const [fields, setFields] = useState<Record<string, Field[]>>({});
  const [selectedFields, setSelectedFields] = useState<Field[]>(initialFields);
  const [loading, setLoading] = useState<boolean>(false);

  const getMaterials = async () => {
    try {
      setLoading(true);
      const payload = {
        uid: auth?.claims?.uid,
        ids: materialVisualizationProps.materialIds,
        required_fields: [
          ...materialVisualizationProps.materialFields,
          ...materialVisualizationProps.required_fields,
        ],
        phase: materialVisualizationProps.phase,
      };
      const res = await MaterialService.create(
        apiRoutes.FIND_MATERIAL_BY_IDS,
        payload
      );
      setMaterials(res.data.body);
    } catch (err) {
      dispatch(alertOpenAction("Failed to fetch materials.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (key: any) => {
    setSelectedFields((selectedFields) =>
      selectedFields.map((field) =>
        field.key === key ? { ...field, selected: !field.selected } : field
      )
    );
  };

  const materialsToCSV = () => {
    // Helper function to handle commas and quotes
    const escapeCSVValue = (value: any) => {
      if (value == null) return ""; // Handle null/undefined values
      const stringValue = value.toString();
      if (stringValue.includes(",") || stringValue.includes('"')) {
        return `"${stringValue.replace(/"/g, '""')}"`; // Escape quotes by doubling them
      }
      return stringValue;
    };

    let csvData = ",";
    materials.map((material) => {
      csvData += `Material: ${escapeCSVValue(material.material_name ?? "-")},`;
    });
    csvData += "\n";
    csvData += ",";
    materials.map((material) => {
      csvData += `Vendor: ${escapeCSVValue(material.vendor_name ?? "-")},`;
    });
    csvData += "\n";
    csvData += "Properties,";
    materials.map((material) => {
      csvData += `Lot Number: ${escapeCSVValue(material.lot_number ?? "-")},`;
    });
    csvData += "\n";

    selectedFields
      .filter(
        (field) =>
          !field.key.includes("-") &&
          field.key !== "attachments" &&
          field.selected
      )
      .map((field) => {
        if (["material_name", "vendor_name", "lot_number"].includes(field.key))
          return;

        csvData += `${getValueFromConcatenatedKey(field.key)},`;
        (materials as Record<string, any>[]).map((material) => {
          const fieldValue = material[field.key];
          csvData += `${
            typeof fieldValue === "string" || typeof fieldValue === "number"
              ? escapeCSVValue(fieldValue ?? "")
              : ""
          },`;
        });
        csvData += "\n";
      });

    selectedFields
      .filter((field) => field.selected)
      .map((field, fieldIndex) => {
        // Split the key to get section and sub-section
        const keys = field.key.split("-");
        if (keys.length === 1) return;
        const section = keys[0];

        if (section === "attachments") return;

        const subSection = keys.length > 2 ? keys[1] : undefined;

        // Determine if we need to show section or sub-section headers
        const showSubSectionHeader =
          fieldIndex === 0 ||
          (selectedFields[fieldIndex - 1].key
            .split("-")
            .slice(0, 2)
            .join("-") !== `${section}-${subSection}` &&
            subSection);

        if (subSection && showSubSectionHeader) {
          csvData += subSection
            .replace(/_/g, " ")
            .replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase());
          csvData += "\n";
        }

        csvData += `${getValueFromConcatenatedKey(field.key)},`;
        (materials as Record<string, any>[]).map((material) => {
          const fieldValue = material[field.key];
          csvData += `${
            typeof fieldValue === "string" || typeof fieldValue === "number"
              ? escapeCSVValue(fieldValue ?? "")
              : ""
          },`;
        });
        csvData += "\n";
      });

    return csvData;
  };

  const downloadVisualizationAsCSV = () => {
    setLoading(true);
    setTimeout(() => {
      const csv = materialsToCSV();

      // Create a Blob object representing the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a temporary link element to download the file
      const aElement = document.createElement("a");
      const url = URL.createObjectURL(blob);
      aElement.href = url;

      // Set the download file name
      aElement.download = `materials-visualized-${new Date().toISOString()}.csv`;

      // Append the link to the document, click it, and remove it
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);

      // Revoke the object URL after the download
      URL.revokeObjectURL(url);
      setLoading(false);
    }, 10);
  };

  const resetFields = () => {
    setSelectedFields(initialFields);
  };

  useEffect(() => {
    if (auth !== "") getMaterials();
  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      const phaseKeys = parseFields(
        materialVisualizationProps.required_fields
      ).reduce<Record<string, Field[]>>((acc, field) => {
        if (field.key === "attachments") return acc;

        const keys = field.key.split("-");
        const itemKey = keys[1]
          ?.replace(/_/g, " ")
          ?.replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase());
        if (acc[itemKey] !== undefined) {
          acc[itemKey].push(field);
        } else {
          acc[itemKey] = [field];
        }
        return acc;
      }, {});
      const attachmentKeys = parseFields(
        materialVisualizationProps.required_attachments
      ).reduce<Record<string, Field[]>>((acc, field) => {
        const keys = field.key.split("-");
        const itemKey =
          keys[1] === "default"
            ? "Attachments"
            : `Attachments-${keys[1]
                ?.replace(/_/g, " ")
                ?.replace(/(?:^|\s|-)\S/g, (c) => c.toUpperCase())}`;
        if (acc[itemKey] !== undefined) {
          acc[itemKey].push(field);
        } else {
          acc[itemKey] = [field];
        }
        return acc;
      }, {});

      setSelectedFields(
        initialFields.filter((field) => field.key !== "attachments")
      );
      setFields({
        Material: parseFields(materialVisualizationProps.materialFields),
        ...phaseKeys,
        ...attachmentKeys,
      });
    }, 10);
  }, []);

  return (
    <SecondaryLayout title="Visualization">
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <BodyHeader
            title="Material Insights: Property Visualization & Filters"
            buttons={[
              {
                isIconButton: true,
                title: "Export CSV",
                icon: ButtonIcons.EXPORT,
                onClick: () => downloadVisualizationAsCSV(),
                type: ButtonTypes.PRIMARY,
              },
            ]}
          />

          <div className="flex flex-col md:flex-row" style={{ height: "82vh" }}>
            <div className="md:w-1/4 bg-gray-100 p-4 rounded-md shadow-lg flex flex-col h-full">
              <form className="flex-1 overflow-y-auto">
                {Object.keys(fields).map((key) => {
                  return (
                    <div>
                      <div className="font-bold mb-1 text-gray-700">
                        {key.startsWith("Attachments-")
                          ? key.substring("Attachments-".length)
                          : ["Psd"].includes(key)
                          ? key.toUpperCase()
                          : key}
                      </div>
                      {fields[key]
                        .filter((field) => !["material_name", "vendor_name", "lot_number", "attachments", "phase"].includes(field.key))
                        .map((field) => (
                          <div key={field.key} className="mb-2">
                            <label className="custom-checkbox">
                              <input
                                className="ip"
                                type="checkbox"
                                checked={
                                  selectedFields.find((f) => f.key === field.key)
                                    ?.selected
                                }
                                onChange={() => handleFieldChange(field.key)}
                                title={field.label}
                              />
                              <span className="check"></span>
                              <span className="txt">{field.label}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  )
                })}
                <div className="mb-2 flex flex-wrap justify-evenly">
                  <Button
                    title="Reset"
                    navigateTo="#"
                    onClick={() => resetFields()}
                    className="theme-btn sm alter"
                    type={ButtonTypes.PRIMARY}
                  />
                </div>
              </form>
            </div>
            <div className="md:w-3/4 mt-4 md:mt-0 md:ml-4 bg-white p-4 rounded-md shadow-lg mt-4 md:mt-0 md:ml-4">
              <MaterialVisualizationTable
                materials={materials}
                selectedFields={selectedFields}
              />
            </div>
          </div>
        </>
      )}
    </SecondaryLayout>
  );
};

export default MaterialVisualize;
