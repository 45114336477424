import React, { useEffect, useRef, useState } from "react";
import useAlert from "../../../../hooks/useAlert";
import FileService from "../../../../services/fileService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { generateMaterialChains, saveMaterialChains } from "../../../../services/materialChains";
import CustomLoader from "../../../../components/common/CustomLoader";
import RenderIf from "../../../../components/common/RenderIf";

const UnchainedForm = (props: any) => {
  // init
  const { unchainedData, setUnchainedData, setSubmitFn } = props;
  const { errorAlert, successAlert } = useAlert();
  const material = useSelector((state: RootState) => state.material);
  const materialId = material?.item?.id;
  const materialItem = material?.item;

  // states
  const [unchainedImages, setUnchainedImages] = useState<any>([]);
  const [excelFile, setExcelFile] = useState<any>(null);
  const [savingImages, setSavingImages] = useState(false);
  const [savingExcelFile, setSavingExcelFile] = useState(false);
  const [saveDataPayload, setSaveDataPayload] = useState<any>(null);

  // refs
  const unchainedImagesRef = useRef<any>();
  const excelInputRef = useRef<any>();
  const btnRef = useRef<any>();

  const saveExcelFile = async () => {
    try {
      if (!excelFile) {
        errorAlert("Please upload the excel file");
        return;
      }
      setSavingExcelFile(true);

      // for file type csv and xlsx
      const mimeType = excelFile?.name.endsWith(".xlsx")
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "text/csv";

      const res = await FileService.create("/files/upload", {
        id: materialId,
        file_for: "Material",
        files: [{
          category: "chainlab-csv",
          field_name: "file",
          key: excelFile?.name,
          mimeType: mimeType,
        }],
      });
      const { data: { body: { files } } } = res;
      const file = files[0];
      const fObj = { ...file, signedUrl: undefined };
      const blob = new Blob([excelFile], { type: excelFile.type });
      await fetch(file.signedUrl, { method: "PUT", body: blob });
      setSavingExcelFile(false);
      return Promise.resolve(fObj);
    } catch (e) {
      errorAlert("Oops! An error occurred");
    }
  }

  const handleDownloadTemplate = async () => {
    const fileKey = 'Unchained Lab Solubility Template Updated.xlsx'
    try {
      const res = await FileService.download({ file: fileKey });
      if (res?.data?.body) {
        const link = document.createElement('a');
        link.href = res.data.body;
        link.download = 'Solubility_Template.xlsx';
        link.click();
      } else {
        console.error("File download URL is not available.");
      }
    } catch (error) {
      console.error("Error while downloading the template:", error);
    }
  };

  const uploadData = async () => {
    const excelData = await saveExcelFile();
    if (savingImages) return;

    if (unchainedImages.length !== 24) {
      errorAlert("Please upload 24 images");
      return;
    }

    setSavingImages(true);
    let res = await FileService.create("/files/upload", {
      id: materialId,
      file_for: "Material",
      files: unchainedImages.map((file: any) => ({
        category: "chainlab",
        field_name: "file",
        key: file.name,
        mimeType: "image/png",
      })),
    });

    const { data: { body: { files } } } = res;
    const unchainedImgs = files.map((f: any) => ({ ...f, signedUrl: undefined }));

    let payload = {
      uid: materialItem.uid,
      vendor_name: materialItem.vendor_name,
      material_name: materialItem.material_name,
      lot_number: materialItem.lot_number,
      material_id: materialId,
      files: unchainedImgs,
      csv: excelData,
    };

    await Promise.all(
      files.map((f: any, index: number) => {
        const file = unchainedImages[index];
        const blob = new Blob([file], { type: file.type });
        return fetch(f.signedUrl,
          {
            method: "PUT",
            body: blob,
          });
      }),
    );

    setSaveDataPayload(payload);
    res = await generateMaterialChains(payload);
    if (res.data.code === 200) {
      setUnchainedData(res.data.body);
    } else {
      errorAlert("Oops! Something went wrong");
    }
    setSavingImages(false);
    successAlert("Files uploaded successfully");
  }

  const handleUnchainedImageChange = (e: any) => {
    if (e.target.files) {
      const totalFiles = e.target.files.length;
      if (totalFiles !== 24) {
        errorAlert(`${totalFiles} images selected. Please select 24 images`);
        setUnchainedImages([]);
        return;
      } else {
        setUnchainedImages(
          Array.from(e.target.files).map((file: any) => ({
            fileIndex: parseInt(file?.name?.split("__")?.[1]?.split(".png")?.[0]),
            file,
          })).sort((a, b) => a.fileIndex - b.fileIndex).map(({ file }) => file),
        );
      }
    }
  }

  const handleExcelFileChange = (e: any) => {
    if (e.target.files) {
      setExcelFile(e.target.files[0]);
    }
  }

  const handleRemoveExcelFile = () => {
    setExcelFile("");
    excelInputRef.current.value = "";
  }

  const saveLabsData = async () => {
    await saveMaterialChains(saveDataPayload);
  }

  useEffect(() => {
    function clickHandler() {
      if (btnRef.current)
        btnRef.current.click();
    }

    setSubmitFn(() => clickHandler);
  }, []);

  return (
    <div>
      <div className="form-group">
        {/* Loading Spinner */}
        <RenderIf condition={savingExcelFile || savingImages}>
          <CustomLoader />
        </RenderIf>

        <div className="custom-upload">
          <label className="ip-wrap" htmlFor="unchained-images">
            <span className="form-label" title={"Upload vial images from Unchained Labs systems"}>Upload Images</span>
            <div className="custom-upload">
              <input type="file" ref={unchainedImagesRef} name="unchained-images" id="unchained-images" multiple
                accept=".png, .jpeg, .jpg"
                max="24" hidden onChange={handleUnchainedImageChange} />
              <button type="button" className="theme-ip" onClick={() => unchainedImagesRef.current.click()}>
                <i className="fa-solid fa-paperclip" />
                <span className="text">No File Chosen</span>
                <span className="theme-btn sm light-btn">Choose</span>
              </button>
            </div>
          </label>

          <div className="uploaded-files" style={{ height: "150px" }}>
            {unchainedImages.map((img: any, index: number) => (
              <div className="upfile" key={index}>
                <span><i className={`fa-solid fa-file`} /></span>
                <div className="text">
                  <div className="name ">
                    <div className="attachment-name">{img.name}</div>
                  </div>
                </div>
              </div>
            ))}

            <RenderIf condition={!(unchainedImages && unchainedImages.length)}>
              <span className="px-3 text-sm">Uploaded files will be visible here</span>
            </RenderIf>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="custom-upload">
          <label className="ip-wrap" htmlFor="meaured-solubility-excel">
            <div className="flex items-center">
              <span className="form-label mr-2" title="Upload solubility values from Unchained Labs systems">
                Upload Excel File
              </span>
              <button type="button" className="download-template flex items-center" onClick={handleDownloadTemplate}>
                <span className="text-sm underline mr-1">Download Template</span>
                <i className="fa-solid fa-download text-xs" style={{ fontSize: '0.75rem' }} />
              </button>
            </div>
            <div className="custom-upload mt-2">
              <input
                ref={excelInputRef}
                type="file"
                name="measured-solubility-excel"
                id="measured-solubility-excel"
                accept=".xlsx, .xls, .csv, .xlxm, .xlsm"
                onChange={handleExcelFileChange}
                hidden
              />
              <button type="button" className="theme-ip" onClick={() => excelInputRef.current.click()}>
                <i className="fa-solid fa-paperclip" />
                <span className="text">No File Chosen</span>
                <span className="theme-btn sm light-btn">Choose</span>
              </button>
            </div>
          </label>

          <div className="uploaded-files" style={{ height: "80px" }}>
            <RenderIf condition={Boolean(excelFile?.name)}>
              <div className="upfile">
                <span><i className={`fa-solid fa-file`} /></span>
                <div className="text">
                  <div className="name">
                    <div className="attachment-name">{excelFile?.name}</div>
                  </div>
                </div>

                <span className="ml-auto full-flex">
                  <i className="fa-solid fa-xmark close block" onClick={handleRemoveExcelFile} />
                </span>
              </div>
            </RenderIf>

            <RenderIf condition={!excelFile}>
              <span className="px-3 text-sm">Uploaded file will be visible here</span>
            </RenderIf>
          </div>
        </div>
      </div>

      <button
        className="theme-btn mb-6 w-full btn-md"
        type="button"
        onClick={uploadData}>
        Show Results
      </button>

      <button type="button" ref={btnRef} className="hidden" onClick={async () => {
        setSavingImages(true);
        await saveLabsData();
        setSavingImages(false);
      }}>Save
      </button>
    </div>
  );
}

export default UnchainedForm;
